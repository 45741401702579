<template>
  <div>
    <v-card class="pa-3">
      <div class="d-flex justify-space-between">
        <div>
          <h3>รายการเปิดออเดอร์</h3>
        </div>
        <!-- <div>asds</div> -->
      </div>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headerOrder"
        :items="orderList"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        @click:row="handleDetail"
        hide-default-footer
      >
        <template v-slot:[`item.order_num`]="{ item }">
          <div class="font-weight-bold">{{ item.order_num }}</div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:[`item.user`]="{ item }"> {{ item.user.fname }} {{ item.user.lname }} </template>
        <template v-slot:[`item.total_all`]="{ item }">
          <div class="font-weight-bold text--black">{{ formatFloat(item.total_all) }}</div>
        </template>
        <template v-slot:[`item.remain`]="{ item }">
          <div class="font-weight-bold text--black">{{ formatFloat(item.remain) }}</div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-row class="flex-nowrap items-center align-center pl-2">
            <div v-if="item.status == 1" class="warning--text">
              <v-chip class="ma-2" color="#FEC400" label text-color="white" small>
                <v-icon left small> mdi-alert-circle-outline </v-icon>
                ค้างชำระ
              </v-chip>
            </div>
            <div v-else class="success--text">
              <v-chip class="ma-2" color="#43C50699" label text-color="white" small>
                <v-icon left small> mdi-check </v-icon>
                จ่ายครบ
              </v-chip>
            </div>
          </v-row>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn block v-if="item.remain != 0" @click.stop="handlePay(item)" color="primary">ชำระ</v-btn>
          <!-- <v-btn block v-else color="success" @click.native.prevent.stop.capture>ชำระแล้ว</v-btn> -->
        </template>
      </v-data-table>
      <div class="d-flex flex-row-reverse pt-2">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import FormatNumber from '@/helper/function'
import moment from 'moment'
export default {
  data() {
    return {
      orderList: [],
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headerOrder: [
        { text: 'หมายเลขออเดอร์', value: 'order_num' },
        { text: 'วันที่สร้างออเดอร์', value: 'created_at' },
        { text: 'ราคาทั้งหมด', value: 'total_all' },
        { text: 'ผู้ซื้อ', value: 'user.fname' },
        { text: 'สถานะ', value: 'status' },
        { text: 'ยอดคงค้าง', value: 'remain' },
        { text: 'Action', value: 'action' },
      ],
    }
  },
  methods: {
    getListOrders() {
      this.$store.dispatch('getListOrderBill').then(res => {
        console.log(res)
        this.orderList = res
      })
    },
    formatInt(num) {
      return FormatNumber.formatInt(num)
    },
    formatFloat(num) {
      return FormatNumber.formatFloat(num)
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm น.')
    },
    handlePay(item) {
      console.log(item)
      // const id = item.id
      this.$store.dispatch('getOrderAgain', item).then(res => {
        console.log(res)
        this.$router.push(`/openbill/detail/${res.order_id}?action=payorders`)
      })
    },
    handleDetail(item) {
      console.log(item)
      this.$router.push(`/orderList/${item.id}`)
    },
  },
  mounted() {
    this.getListOrders()
  },
}
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table {
  width: 100%;
  border-spacing: 0;
  cursor: pointer;
}
</style>
