var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('h3',[_vm._v("รายการเปิดออเดอร์")])])]),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headerOrder,"items":_vm.orderList,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.handleDetail},scopedSlots:_vm._u([{key:"item.order_num",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.order_num))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.fname)+" "+_vm._s(item.user.lname)+" ")]}},{key:"item.total_all",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold text--black"},[_vm._v(_vm._s(_vm.formatFloat(item.total_all)))])]}},{key:"item.remain",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold text--black"},[_vm._v(_vm._s(_vm.formatFloat(item.remain)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap items-center align-center pl-2"},[(item.status == 1)?_c('div',{staticClass:"warning--text"},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#FEC400","label":"","text-color":"white","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" ค้างชำระ ")],1)],1):_c('div',{staticClass:"success--text"},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#43C50699","label":"","text-color":"white","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-check ")]),_vm._v(" จ่ายครบ ")],1)],1)])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.remain != 0)?_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handlePay(item)}}},[_vm._v("ชำระ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row-reverse pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }